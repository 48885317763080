@import "colors-dsi";
@import "norm";
@import "fonts";
@import "buttons";
@import "range";
@import "switches";
@import "header-dsi";
@import "dashboard";
@import "learning_journey";
@import "learning_path";
@import "learning_course";
@import "learning_plan";
@import "course";
@import "journal-dsi";
@import "resources";
@import "jump_start_learning";
@import "community";
@import "yammer";
@import "progress";
@import "search-dsi";
@import "recommended";
@import "search_autocomplete";
@import "lxp-async-button";
@import "lxp-likes";
@import "lxp-self-progress";
@import "pagination";
@import "footer";
@import "simulation";
@import "languages";
@import "resources-dsi";
@import "dashboard-dsi";
@import "typography-dsi";
@import "login-dsi";
body {
  margin: 0 0 0 0;
  color: $almost-black;
  font-size: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif !important;
}

// WIP
.navbar.primary .form-control.search {
  border-color: $grey-blue;
  background-color: $grey-blue;
}

.container-wrapper {
  height: 100%;
  background-color: white;

}

.clearer {
  clear: both;
}
a {

  &.no-word-break {
    word-break: keep-all;
  }
}


.dsi-resource{

  p{
    font-size: 18px;
  }

  li{
    font-size: 18px;
  }

  h3{
    font-size: 30px;
  }
}
.container.resource {
  background-color: $white;
  padding: 0;
  box-shadow: unset;

  .resources-header {
    padding: 50px;
    color: $white;
    font-size: 18px;
    background-color: $dark-blue;
    opacity: 90%;

    &.minus-p-bottom {
      padding-bottom: 0;
    }

    h2 {
      font-size: 40px;
    }

    blockquote {
      margin: 2rem 0;
      font-size: 25px;
      font-family:  'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
      //text-align: right;
      font-weight: 300;
    }
  }

  .resources-content {
    padding: 50px;
    background-color: $white;
  }

}

.footer {
  overflow: hidden;
  height: 48px;
  font-size: 14px;
  color: $almost-black;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-image-source: linear-gradient(to left, #005baa, #509ed5);
  background-color: $white;

  .logo-footer {
    max-width: 188px;
    max-height: 43px;
  }

  #footer-curves {
    position: absolute;
    top: -7px;
    right: -45px;
    z-index: 0;
    height: 44px;
    width: auto;
  }
  #footer-continue {
    position: relative;
    z-index: 1;
    color: $white;
    border-color: $white;
    border-radius: 33px;

    &:hover, &:focus {
      color: $white;
    }
  }
}

.continue-footer-right{
  background-image: linear-gradient(to right, white, $blue);
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  margin-bottom: 0;
  justify-content: flex-end;
  font-size: 22px;
  font-weight: 600;
  padding-right: 0;
  margin-right: 0;
  color: white;
}

.continue-nav-wrapper{
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
}


.lxp-status{
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;

  svg{
    width: 15px;
    height: 15px;
  }
}

.dsi-nav{

  svg{
    width: 50px;
    height: 50px;
  }
}

.reflection-page{

  .reflection-img{
    width: 100%
  }
}

ul.ul-split-two {
  @media screen and (min-width: 992px) {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}



